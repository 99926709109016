import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import './post.css';
import SEO from '../components/seo';
import PostHero from '../components/post-hero';

const PostTemplate = (props) => {
    return (
    <Layout>
       <SEO title={props.data.contentfulStoryPost.title} />
    
        <PostHero
            title={props.data.contentfulStoryPost.title}
            description={props.data.contentfulStoryPost.shortIntroBlurb}
            image={props.data.contentfulStoryPost.bannerImage.fluid.src}    />

        <div className="post-content">

            <div className="author-block">
                <div className="author">{props.data.contentfulStoryPost.author.name}</div>
                <div className="title-affiliation">{props.data.contentfulStoryPost.author.titleOrAffiliation}</div>
            </div>

            <div dangerouslySetInnerHTML={
                    {__html: `${props.data.contentfulStoryPost.postContent.childMarkdownRemark.html}`}
                } />
            
        </div>

  </Layout>
)
}

export default PostTemplate;


export const query = graphql`
 query PostTemplate($id: String!) {
    contentfulStoryPost(id: {eq: $id}) {
        id
        title
        slug
        author {
            name
            titleOrAffiliation
          }
          bannerImage {
            fluid(maxWidth: 2500, quality: 100) {
                ...GatsbyContentfulFluid
                src
              }
          }
          createdAt
          shortIntroBlurb
          postContent {
            childMarkdownRemark {
              html
            }
          }
          slug
          spiritType {
            title
          }
    }
 }
`
